import "./TandC.scss";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
function TandC() {
    return (
        <>
            <Navbar />
            <section className="tandc-section">
            <h1>Website Terms and Conditions of Use</h1>
            <strong>1. Terms</strong>
            <p>By accessing this Website, accessible from https://www.hands-off.app, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>

            <strong>2. Disclaimer</strong>
            <p>All the materials on https://www.hands-off.app's Website are provided “as is”. https://www.hands-off.app makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, https://www.hands-off.app does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

            <strong>3. Limitations</strong>
            <p> https://www.hands-off.app or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on https://www.hands-off.app's Website, even if https://www.hands-off.app or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

            <strong>4. Revisions and Errata</strong>
            <p>The materials appearing on https://www.hands-off.app's Website may include technical, typographical, or photographic errors. https://www.hands-off.app will not promise that any of the materials in this Website are accurate, complete, or current. https://www.hands-off.app may change the materials contained on its Website at any time without notice. https://www.hands-off.app does not make any commitment to update the materials.</p>

            <strong>5. Links</strong>
            <p>https://www.hands-off.app has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by https://www.hands-off.app of the site. The use of any linked website is at the user's own risk.</p>

            <strong>6. Site Terms of Use Modifications</strong>
            <p>https://www.hands-off.app may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

            <strong>7. Governing Law</strong>
            <p>Any claim related to https://www.hands-off.app's Website shall be governed by the laws of Country without regards to its conflict of law provisions.</p>
            </section>
            <Footer />
        </>)
}

export default TandC;